import React from "react";
import "../App.css";

function Footer() {
    return (<div class="footer">
        <h3>
            Powered by Medumeta.com
        </h3>
    </div>)
}

export default Footer;